import axios from "axios"
import * as Sentry from "@sentry/react"

import { API_URL } from "../utils/constants"

const onRequest = (config) => {
  const token = sessionStorage.getItem("jwt")
  config.headers.authorization = `JWT ${token}`
  return config
}

const onRequestError = (error) => {
  return Promise.reject(error)
}

const onResponse = (response) => {
  return response
}

const onResponseError = async (error) => {
  if (error.response) {
    console.debug("Response Error:", JSON.stringify(error.response))
    // Access Token was expired
    if (
      error.response.status === 401 &&
      error.response.data.detail === "Signature has expired."
    ) {
      const storedToken = sessionStorage.getItem("jwt")
      try {
        const result = await axios.post(`${API_URL}/token/refresh/`, {
          token: storedToken,
        })
        if (result.data) {
          sessionStorage.setItem("jwt", result.data.token)
        }
        return
      } catch (_error) {
        sessionStorage.removeItem("jwt")
        return Promise.reject(_error)
      }
    } else {
      Sentry.captureException(error)
      console.error("API Error:", error, error.response.data)
      return Promise.reject(error)
    }
  }
  Sentry.captureException(error)
  return Promise.reject(error)
}

export const setupInterceptorsTo = (axiosInstance) => {
  axiosInstance.interceptors.request.use(onRequest, onRequestError)
  axiosInstance.interceptors.response.use(onResponse, onResponseError)
  return axiosInstance
}
