import axios from "axios"
import * as Sentry from "@sentry/react"

import { API_URL } from "../utils/constants"

export const fetchMerchant = async (mid) => {
  console.log("Fetching Merchant:", mid)
  try {
    const response = await axios.get(`${API_URL}/v1/merchants/search/${mid}`)
    return { success: true, data: response.data }
  } catch (err) {
    Sentry.captureException(err)
    console.error("Error Fetching Merchant:", err, err.response.data)
    return { success: false, error: err.message }
  }
}

export const getCategories = async (url) => {
  console.log("Fetching Product Categories")
  try {
    const response = await axios.get(`${url}categories/`)
    return { success: true, data: response.data }
  } catch (err) {
    Sentry.captureException(err)
    console.error("Error Fetching Categories:", err, err.response.data)
    return { success: false, error: err.message }
  }
}

export const getProducts = async (url) => {
  console.log("Fetching Products")
  try {
    const response = await axios.get(`${url}`)
    return { success: true, data: response.data }
  } catch (err) {
    Sentry.captureException(err)
    console.error("Error Fetching Products:", err, err.response.data)
    return { success: false, error: err.message }
  }
}
