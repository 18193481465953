import React, {
  useState,
  createContext,
  useCallback,
  useContext,
  useEffect,
} from "react"
import axios from "axios"
import * as Sentry from "@sentry/react"

import api from "../services/api"
import decodeJwt from "../utils/decodeJwt"
import { getTrackerSession } from "../utils/openReplay"
import { API_URL, APPLICATION_STORE } from "../utils/constants"

const AuthContext = createContext(null)

export function AuthProvider({ children }) {
  const sessionStorageValue = sessionStorage.getItem("jwt")
  const [authed, setAuthed] = useState(sessionStorageValue !== null)
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState(null)

  const refresh = useCallback(async () => {
    const token = sessionStorage.getItem("jwt")

    if (token === null) return false

    try {
      const result = await api.post(`${API_URL}/token/refresh/`, {
        token,
      })
      if (result.data) {
        setAuthed(true)
        sessionStorage.setItem("jwt", result.data.token)
        return true
      }
    } catch (err) {
      Sentry.captureException(err)
      console.log("failed Auth Refresh")
      logout()
      return false
    }
  }, [])

  const getUser = useCallback(async () => {
    try {
      const response = await api.get(`${API_URL}/v1/users/me/`)
      console.debug("Got User:", response)

      if (response.data) {
        const tracker = getTrackerSession()
        if (tracker !== null) {
          tracker.setUserID(response.data.id)
          tracker.setMetadata("first_name", response.data.first_name)
          tracker.setMetadata("last_name", response.data.last_name)
          tracker.setMetadata("email", response.data.email)
        }
        setUser(response.data)
      }
    } catch (err) {
      Sentry.captureException(err)
      console.error("Error Fetching User", err)
    }
  }, [])

  const loginCheck = useCallback(async () => {
    const sessionStorageValue = sessionStorage.getItem("jwt")
    if (sessionStorageValue === null) return false
    await getUser()
    return await refresh()
  }, [refresh, getUser])

  useEffect(() => {
    loginCheck().then(async (activeUser) => {
      console.debug("Doing login check...")
      if (activeUser) {
        await getUser()
        setAuthed(true)
        setLoading(false)
      } else {
        setAuthed(false)
        setLoading(false)
      }
    })

    const intervalId = setInterval(() => {
      if (!authed) return
      refresh().then((refreshed) => {
        if (refreshed) {
          console.log("refresh successful")
        } else {
          console.log("refresh unsuccessful")
        }
      })
    }, 720000)

    return () => clearInterval(intervalId)
  }, [authed, loginCheck, refresh, getUser])

  const updateAuth = async (token) => {
    sessionStorage.setItem("jwt", token)
    await getUser()
    setAuthed(true)
  }

  const verifyToken = async (token) => {
    try {
      const response = await axios.post(`${API_URL}/token/verify/`, { token })
      if (response.data) {
        setAuthed(true)
        sessionStorage.setItem("jwt", response.data.token)
        sessionStorage.setItem("token", decodeJwt(response.data.token))
        return { success: true, data: response.data }
      }
    } catch (err) {
      Sentry.captureException(err)
      return { success: false, error: err }
    }
  }

  const verifyOtp = async (id, payload) => {
    try {
      const response = await axios.post(
        `${API_URL}/v1/otp/${id}/verify/`,
        payload
      )
      if (response.data) {
        setAuthed(true)
        sessionStorage.setItem("jwt", response.data.token)
        sessionStorage.setItem("token", decodeJwt(response.data.token))
        await getUser()
        return { success: true, data: response.data }
      }
    } catch (err) {
      Sentry.captureException(err)
      return { success: false, error: err }
    }
  }

  const getOtp = async (payload) => {
    try {
      const result = await axios.post(`${API_URL}/v1/otp/`, payload)
      if (result.data) {
        return { success: true, data: result.data }
      }
    } catch (err) {
      Sentry.captureException(err)
      return { success: false, error: err }
    }
  }

  const login = async (username, password) => {
    try {
      const result = await api.post(`${API_URL}/token/`, {
        username,
        password,
      })
      if (result.data) {
        console.log("User has logged in")
        setAuthed(true)
        sessionStorage.setItem("jwt", result.data.token)
        sessionStorage.setItem("token", decodeJwt(result.data.token))
        await getUser()
        return { success: true, data: result.data }
      }
    } catch (err) {
      Sentry.captureException(err)
      return { success: false, error: err }
    }
  }

  const logout = async () => {
    sessionStorage.removeItem("jwt")
    sessionStorage.removeItem(APPLICATION_STORE)
    setAuthed(false)
  }

  return (
    <AuthContext.Provider
      value={{
        authed,
        setAuthed,
        login,
        logout,
        refresh,
        updateAuth,
        user,
        loading,
        getOtp,
        verifyOtp,
        verifyToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)
