import * as Sentry from "@sentry/react"

import api from "./api"
import { API_URL } from "../utils/constants"

export const getLoans = async () => {
  console.log("Fetching Loans")
  try {
    const response = await api.get(`${API_URL}/v1/loans/`)
    return { success: true, data: response.data }
  } catch (err) {
    Sentry.captureException(err)
    console.error("Error Fetching Loans", err, err.response.data)
    return { success: false, error: err.response }
  }
}

export const getLoan = async (loanId) => {
  console.log("Fetching Loan:", loanId)
  try {
    const response = await api.get(`${API_URL}/v1/loans/${loanId}/`)
    const { data } = response
    const { id, status } = data

    window.parent &&
      window.parent.postMessage({ type: "loan", id, status }, "*")

    return { success: true, data }
  } catch (err) {
    Sentry.captureException(err)
    console.error("Error Fetching Loan:", err, err.response.data)
    return { success: false, error: err.response }
  }
}
